document.addEventListener('DOMContentLoaded', function() {

    const gliders = document.querySelectorAll('.glider')
    if(!gliders.length) return
    
    new Glider(document.querySelector('.glider'), {
      slidesToShow: 8.5,
      slidesToScroll: 2,
      draggable: true,
      itemWidth: 120,
      // dots: '.dots',
      arrows: {
        prev: '.glider-prev',
        next: '.glider-next'
      }
      });
});