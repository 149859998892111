$('.cs-carousel.owl-carousel').owlCarousel({
    items:3,
    autoWidth:true,
    lazyLoad:true,
    loop:true,
    margin:20,
    center: true,
    loop:true,
    navText: ["<img src='/assets/images/left.png>", "<img src='/assets/images/right.png>"],
    // navText:["<div class='nav-btn prev-slide'></div>","<div class='nav-btn next-slide'></div>"],
    dots: false,
  responsiveClass:true,
  autoplay: false,
  nav: true,
  responsive:{
    0:{
        items:2,
        dots: false,
        center: false
    },
    640:{
        items:2.5,
        center: true,
    },
    900:{
        items:3.5
    },
    1200:{
      items:3.5,
    }
  }
});